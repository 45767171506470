import Card from '@app/components/card/card.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import { BranchItemFragment, PickedProductInput, ServiceItemFragment } from '@app/graphql/types/graphql.ts';
import { getFragmentData } from '@app/graphql/types';
import { ServiceProductFragment } from '@app/page/online-order/model/appointment-fragment.ts';
import { MenuSectionFragment } from '@app/page/online-order/model/menu-section-fragment.ts';
import {
  MenuProductConfigurationFragment,
  MenuProductConfigurationValueFragment,
} from '@app/page/online-order/model/menu-product-fragment.ts';
import { formatRawCurrency } from '@app/components/price/currency-formatter.ts';
import {
  AppointmentMonitor,
  useAppointmentInfo,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useTranslate } from '@tolgee/react';
import getImageLink from '@app/utils/get-image-link.ts';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';

export default function AppointmentServiceStart(props: { menu: ServiceItemFragment; branch: BranchItemFragment }) {
  const { branch, menu } = props;
  const selectProduct = useAppointmentInfo((state) => state.selectProduct);
  const setAppointmentMonitor = useAppointmentInfo((state) => state.setAppointmentMonitor);
  const { t } = useTranslate();
  const handleClick = (pickedProduct: PickedProductInput) => {
    selectProduct(pickedProduct);
    setAppointmentMonitor(AppointmentMonitor.time);
  };

  return (
    <div className="flex w-full flex-col items-center bg-gray-100 sm:justify-center">
      <Card className="min-w-full max-w-4xl sm:mt-24 sm:min-w-[750px]">
        <CardContent>
          <div>
            <header className="mb-10 flex w-full items-center justify-between">
              {/*Logo*/}
              {branch.company.logo ? (
                <img
                  alt="logo"
                  src={getImageLink(branch.company.logo)}
                  className="h-12 w-auto object-contain pl-4 sm:h-20"
                />
              ) : (
                <div></div>
              )}

              {/*Branch information*/}
              <div className="flex scale-75 flex-col items-end text-right text-gray-500 sm:scale-100">
                <p className="text-xl">{branch.name}</p>
                <p className="text-sm">{`${branch.streetAddress}, ${branch.postalCode} ${branch.addressLocality}`}</p>
                <p className="text-sm">{branch.website}</p>
              </div>
            </header>
            <section>
              <ul className="flex flex-col gap-8">
                {menu.sections
                  .sort((a, b) => {
                    return naturalCompare(
                      getFragmentData(MenuSectionFragment, a).code,
                      getFragmentData(MenuSectionFragment, b).code
                    );
                  })
                  .map((sectionFragment) => {
                    // get section fragment
                    const section = getFragmentData(MenuSectionFragment, sectionFragment);

                    // product list interface
                    const menuProductList = menu.menuProducts
                      .map((productFragment) => {
                        return getFragmentData(ServiceProductFragment, productFragment);
                      })
                      .filter((product) => {
                        return product.sectionId == section.id;
                      })
                      .sort((a, b) => {
                        return naturalCompare(a.code, b.code);
                      })
                      .map((product) => {
                        const configuration = getFragmentData(MenuProductConfigurationFragment, product.configurations);
                        const configurationValue = getFragmentData(
                          MenuProductConfigurationValueFragment,
                          configuration[0].values
                        );
                        const currencyData = formatRawCurrency(
                          configurationValue[0].price,
                          branch.company.settings.currency
                        );

                        return (
                          <li
                            key={product.id}
                            className="flex w-full items-center justify-between rounded-xl border bg-stone-100 px-4 py-3 shadow-sm hover:bg-stone-200"
                            onClick={() => {
                              // Covert from raw product to pickedProduct
                              const convertToPickedProductData: PickedProductInput = {
                                productID: product.id,
                                pickedConfiguration: [
                                  {
                                    configurationID: configuration[0].id,
                                    valueID: configurationValue[0].id,
                                  },
                                ],
                              };

                              handleClick(convertToPickedProductData);
                            }}
                          >
                            <div className="space-y-2">
                              <p className="text-sm text-black">{product.title}</p>
                              <p className="mr-8 line-clamp-2 max-w-xl text-ellipsis text-xs text-gray-700">
                                {product.description}
                              </p>
                              <p className="text-xs text-gray-500">
                                {product.appointmentMenuProductData.duration}{' '}
                                {t('storefront-appointment.create.minutes', 'Minutes')}
                              </p>
                            </div>
                            <div>
                              <p className="text-base text-gray-500">{currencyData.value + currencyData.symbol}</p>
                            </div>
                          </li>
                        );
                      });

                    if (menuProductList.length == 0) {
                      return null;
                    }

                    return (
                      <li key={section.id}>
                        <Disclosure as="div" defaultOpen={false}>
                          <DisclosureButton className="group flex w-full items-center justify-between gap-x-8 rounded-md px-4">
                            <div className="space-y-2 text-start">
                              <p className="text-lg font-semibold text-stone-800">
                                {section.name}
                              </p>
                              <p className="line-clamp-2 text-xs text-stone-500">{section.description}</p>
                            </div>
                            <ChevronDownIcon className="size-5 min-w-5 fill-white/60 group-data-[open]:rotate-180 group-data-[hover]:fill-white/50" />
                          </DisclosureButton>
                          <DisclosurePanel className="mt-4 px-4 text-sm/5 text-white/50">
                            <ul className="flex flex-col gap-2">{menuProductList}</ul>
                          </DisclosurePanel>
                        </Disclosure>
                      </li>
                    );
                  })}
              </ul>
            </section>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
