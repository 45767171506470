import blackLogo from '../../src/assets/black-logo.png';
import ninetysix from '../../src/assets/ninetysix-black.png';

export default function FooterBar(props: { classNames: string }) {
  return (
    <footer className={props.classNames}>
      {/*desktop*/}
      <div className="mx-auto hidden w-full max-w-3xl flex-col overflow-hidden pb-12 pt-16 md:block">
        <div
          aria-label="Footer"
          className="-mb-6 flex flex-wrap items-center justify-center space-x-2 sm:mr-10 sm:flex-nowrap"
        >
          <img className="mb-1 h-24 w-auto" src={ninetysix} alt="Digital InHouse 2" />
          <img className="h-10" src={blackLogo} alt="Digital InHouse 2" />
        </div>
        <div className="w-full text-left text-center text-xs leading-5 text-gray-500">
          ©2022-2025 All Rights Reserved. inHouse Management App® is a registered software and trademark of Ninety Six
          Digital Ltd. Terms and conditions, features, support, pricing, and service options subject to change without
          notice. By accessing and using this page you agree to the Terms and Conditions.
        </div>
        <div className="mt-4 text-center text-xs font-semibold leading-5 text-black">
          <a href="https://ninetysixdigital.com/"> About us </a>|
          <a href="https://96digital.de/privacy-policy"> Privacy </a>|
          <a href="https://96digital.de/term-of-use"> Terms </a>| <a href="https://ninetysixdigital.com/"> Legal </a> |
          <a href="https://ninetysixdigital.com/"> Cookie Preferences</a>
        </div>
      </div>

      {/*mobile*/}
      <div className="mx-auto block w-full max-w-2xl overflow-hidden pb-12 pt-24 md:hidden">
        <div
          aria-label="Footer"
          className="-mb-6 flex flex-wrap items-center justify-center space-x-2 sm:mr-10 sm:flex-nowrap"
        >
          <img className="mb-1 h-24 w-auto" src={ninetysix} alt="Digital InHouse 2" />
          <img className="h-10" src={blackLogo} alt="Digital InHouse 2" />
        </div>

        <p className="mt-4 text-center text-xs leading-5 text-gray-500">
          ©2022-2025 All Rights Reserved. inHouse Management App® is a registered software and trademark of Ninety Six
          Digital Ltd. Terms and conditions, features, support, pricing, and service options subject to change without
          notice. By accessing and using this page you agree to the Terms and Conditions.
        </p>
        <p className="mt-4 text-center text-xs font-semibold leading-5 text-black">
          <a href="https://ninetysixdigital.com/"> About us </a>|
          <a href="https://96digital.de/privacy-policy"> Privacy </a>|
          <a href="https://96digital.de/term-of-use"> Terms </a>| <a href="https://ninetysixdigital.com/"> Legal </a> |
          <a href="https://ninetysixdigital.com/"> Cookie Preferences</a>
        </p>
      </div>
    </footer>
  );
}
