import { useTranslate } from '@tolgee/react';
import {
  BranchItemFragment,
  OnlineOrderAvailabilityItemFragment,
  OnlineOrderTimeSlot,
  OpeningTimeServiceType,
} from '@app/graphql/types/graphql.ts';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { Moment } from 'moment';

export default function OnlineOrderDateInput(props: {
  branch: BranchItemFragment;
  currentValue?: string;
  handleFunction: (value: string) => void;
  availabilityData: OnlineOrderAvailabilityItemFragment;
  type: OpeningTimeServiceType.OnlineOrderDelivery | OpeningTimeServiceType.OnlineOrderPickup;
}) {
  const { t } = useTranslate();

  const [timeSlots, setTimeSlots] = useState<OnlineOrderTimeSlot[] | undefined>(undefined);
  const [selectedBlock, setSelectedBlock] = useState<Moment | undefined>(undefined);

  useEffect(() => {
    if (props.type == OpeningTimeServiceType.OnlineOrderPickup) {
      setTimeSlots(props.availabilityData.pickup.orderForLater);
    }

    if (props.type == OpeningTimeServiceType.OnlineOrderDelivery) {
      setTimeSlots(props.availabilityData.delivery.orderForLater);
    }
  }, [props.availabilityData, props.type, setTimeSlots]);

  useEffect(() => {
    if (props.currentValue) {
      setSelectedBlock(moment.tz(props.currentValue, props.branch.company.settings.timezone));
    }
  }, [props.branch.company.settings.timezone, props.currentValue]);

  if (!timeSlots || timeSlots.length == 0) {
    return (
      <div className="flex w-full items-center justify-center p-4 text-sm text-gray-400">
        {t('storefront-appointment.create.service-and-time.form-input.date.no-time', 'No available time')}
      </div>
    );
  }

  const timeHandler = (value: string) => {
    const formatTimeString = moment.tz(value, props.branch.company.settings.timezone).format('YYYY-MM-DDTHH:mm');
    props.handleFunction(formatTimeString);
  };

  return (
    <div className="p-4">
      <div className="grid max-h-48 grid-cols-3 gap-2 overflow-scroll rounded-xl bg-neutral-200 p-6 [&::-webkit-scrollbar]:w-0">
        {timeSlots.map((time) => {
          return (
            <div
              key={time.startTime}
              onClick={() => {
                if (time.spotsOpen > 0) {
                  timeHandler(time.startTime);
                }
              }}
              className={classNames(
                'flex cursor-pointer items-center justify-center rounded-lg px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-indigo-600 hover:text-white',
                {
                  'bg-indigo-600 text-white hover:bg-indigo-400': moment
                    .tz(time.startTime, props.branch.company.settings.timezone)
                    .isSame(selectedBlock, 'minutes'),
                },
                {
                  'opacity-50 hover:bg-neutral-200 hover:text-gray-900': time.spotsOpen <= 0,
                }
              )}
            >
              <span>{moment.tz(time.startTime, props.branch.company.settings.timezone).format('HH:mm')}</span>
              {time.spotsOpen <= 0 && <span className="font-light italic">{t('reservation.full-slot', '(Full)')}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
}
