import {
  BranchItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderConfirmationState,
  OnlineOrderItemFragment,
} from '@app/graphql/types/graphql.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import ContactAndAddressInfo from '@app/page/online-order/review/component/contact-and-address-info.tsx';
import BusinessCard from '@app/page/online-order/product-picker/component/business-card.tsx';
import { useTranslate } from '@tolgee/react';
import CancelOnlineOrderButton from '@app/page/online-order/order-detail/component/cancel-online-order-button.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import SecondaryButton from '@app/components/secondary-button.tsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import { StatusNotificationType } from '@app/page/online-order/order-detail-page/component/status-notification-type.ts';
import StatusNotification from '@app/page/online-order/order-detail-page/component/status-notification.tsx';

export default function OrderDetailPage(props: {
  onlineOrder: OnlineOrderItemFragment;
  branch: BranchItemFragment;
  configuration: OnlineOrderBranchConfigurationItemFragment;
}) {
  const { t } = useTranslate();
  const navigate = useNavigate();

  const productPageLink = `/online-order/start?branchID=${props.branch.id}`;
  const websiteLink = props.branch.website;

  const estimatedTime =
    props.onlineOrder.confirmationState !== OnlineOrderConfirmationState.Pending
      ? props.onlineOrder.expectedTime
        ? moment
            .tz(props.onlineOrder.expectedTime, props.branch.company.settings.timezone)
            .add(props.onlineOrder.estimatedDuration, 'minutes')
            .format('HH:mm')
        : moment
            .tz(props.onlineOrder.createdAt, props.branch.company.settings.timezone)
            .add(props.onlineOrder.estimatedDuration, 'minutes')
            .format('HH:mm')
      : null;

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <BusinessCard branch={props.branch} configuration={props.configuration} />

        <StatusNotification
          state={props.onlineOrder.state}
          mode={StatusNotificationType.CARD}
          phone={props.branch.contactPhone ?? ''}
          estimatedTime={estimatedTime}
        />

        <StatusNotification
          state={props.onlineOrder.state}
          mode={StatusNotificationType.POP_UP}
          phone={props.branch.contactPhone ?? ''}
          estimatedTime={estimatedTime}
        />

        <CartSideBarPreview order={props.onlineOrder} editable={false} mode={CartSideBarPreviewMode.Full} />

        <ContactAndAddressInfo
          onlineOrder={props.onlineOrder}
          configuration={props.configuration}
          branch={props.branch}
        />

        <div className="flex justify-between gap-x-4 gap-y-3">
          {websiteLink && (
            <a className="grow" href={websiteLink}>
              <SecondaryButton className="w-full rounded-lg py-4">
                {t('online-order.button.back-to website', 'Back to website')}{' '}
              </SecondaryButton>
            </a>
          )}

          <PrimaryButton
            onClick={() => {
              navigate(productPageLink);
            }}
            className="grow rounded-lg py-4"
          >
            {t('online-order.button.new-order', 'New order')}
          </PrimaryButton>

          {props.branch.contactPhone && (
            <div className="grow">
              <CancelOnlineOrderButton className="rounded-lg py-4" phone={props.branch.contactPhone ?? ''} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
