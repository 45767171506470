import {
  BranchItemFragment,
  OnlineOrderBranchConfigurationItemFragment,
  OnlineOrderDeliveryFeeCalculationStatus,
  OnlineOrderItemFragment,
  OnlineOrderUserState,
} from '@app/graphql/types/graphql.ts';
import { useOnlineOrderChangeUserState } from '@app/page/online-order/detail/logic/use-online-order-change-user-state.ts';
import { captureException } from '@sentry/react';
import { useTranslate } from '@tolgee/react';
import { CartSideBarPreviewMode } from '@app/page/online-order/fill-contact-info/utils/cart-side-bar-preview-mode-enum.ts';
import CartSideBarPreview from '@app/page/online-order/fill-contact-info/component/cart-side-bar-preview.tsx';
import ContactAndAddressInfo from '@app/page/online-order/review/component/contact-and-address-info.tsx';
import SuperButton from '@app/components/super-button.tsx';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import ApplicationErrorView from '@app/module/error/application-error-view.tsx';
import Spinner from '@app/components/spinner.tsx';
import { analyticOrderPreview } from '@app/page/online-order/product-picker/utils/online-order-analytic.ts';
import { useCurrency } from '@app/components/price/use-currency.ts';
import { formatRawCurrency } from '@app/components/price/currency-formatter.ts';

export default function OnlineOrderReviewPage(props: {
  onlineOrder: OnlineOrderItemFragment;
  branch: BranchItemFragment;
  configuration: OnlineOrderBranchConfigurationItemFragment;
}) {
  const { onlineOrder } = props;
  const currency = useCurrency();
  const [changeUserState, { error, loading }] = useOnlineOrderChangeUserState();

  const backToFillAddress = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.FillContactInfo,
      },
    }).catch(captureException);
  };

  const submitHandler = () => {
    changeUserState({
      variables: {
        onlineOrderID: props.onlineOrder.id,
        state: OnlineOrderUserState.Done,
      },
    })
      .then(() => {
        let discountPrice;
        if (props.onlineOrder.pickupDiscount) {
          discountPrice = Number(formatRawCurrency(props.onlineOrder.pickupDiscount, currency).value);
        }

        if (props.onlineOrder.deliveryDiscount) {
          discountPrice = Number(formatRawCurrency(props.onlineOrder.deliveryDiscount, currency).value);
        }

        analyticOrderPreview({
          deliveryMode: props.onlineOrder.deliveryMode,
          timeMode: props.onlineOrder.expectedTime ? 'Order for later' : 'Now',
          totalPrice: props.onlineOrder.totalPrice
            ? Number(formatRawCurrency(props.onlineOrder.totalPrice, currency).value)
            : null,
          deliveryFee: props.onlineOrder.deliveryPrice
            ? Number(formatRawCurrency(props.onlineOrder.deliveryPrice, currency).value)
            : null,
          discountPrice: discountPrice,
        });

        localStorage.setItem('DONE_STAGE_FIRST_ACCESS', Date.now().toString());
      })
      .catch(captureException);
  };

  const validateForOrderButton = () => {
    if (onlineOrder.deliveryFeeCalculationStatus === OnlineOrderDeliveryFeeCalculationStatus.OutOfRange) {
      return true;
    }
    return false;
  };

  const { t } = useTranslate();

  return (
    <div className="flex items-center justify-center bg-gray-100 sm:p-8">
      <div className="max-w-[1000px] grow space-y-4 sm:basis-2/3">
        <ApplicationErrorView error={error} />
        <BackStateCard
          loading={loading}
          backToFillAddress={backToFillAddress}
          t={(key: string, defaultValue: string) => {
            return t(key, defaultValue);
          }}
        />

        {/*<BusinessCard branch={props.branch} />*/}

        <CartSideBarPreview order={props.onlineOrder} editable={false} mode={CartSideBarPreviewMode.Full} />

        <ContactAndAddressInfo
          onlineOrder={props.onlineOrder}
          branch={props.branch}
          configuration={props.configuration}
        />

        <div className="px-1 sm:px-0">
          <SuperButton disabled={validateForOrderButton()} onClick={submitHandler}>
            {loading ? <Spinner /> : <span>{t('online-order.review-page.review-side-bar.button.order', 'Order')}</span>}
          </SuperButton>
        </div>
      </div>
    </div>
  );
}

const BackStateCard = (props: {
  backToFillAddress: () => void;
  t: (key: string, defaultValue: string) => string;
  loading: boolean;
}) => {
  return (
    <Card className="sticky top-0 z-50 shadow-md sm:relative sm:shadow-none">
      <CardHeader
        title={!props.loading ? props.t('online-order.review-page.back-to-edit-address', 'Back to edit address') : ''}
        handleBackButtonClick={props.backToFillAddress}
        withBackButton={true}
      >
        {props.loading && <Spinner />}
      </CardHeader>
    </Card>
  );
};
