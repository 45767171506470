import {
  PickedProduct,
  pickedProductID,
  useProductPicker,
} from '@app/page/online-order/product-picker/logic/use-product-picker.ts';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';
import {
  analyticProductQuantityDecrease,
  analyticProductQuantityIncrease,
} from '@app/page/online-order/product-picker/utils/online-order-analytic.ts';
import { MenuProductItemFragment } from '@app/graphql/types/graphql.ts';

const CartButton = (props: { pickedProduct: PickedProduct; isUpdate?: boolean; product: MenuProductItemFragment }) => {
  const addToCart = useProductPicker((state) => state.add);
  const decreaseFromCart = useProductPicker((state) => state.decrease);

  const addToCartHandler = () => {
    addToCart({
      productID: props.pickedProduct.productID,
      quantity: 1,
      configurations: props.pickedProduct.configurations,
    });
  };

  const deleteHandler = (pickedProductID: string) => {
    decreaseFromCart(pickedProductID);
  };

  return (
    <div className="flex flex-col items-center justify-between gap-1.5">
      <div
        className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-gray-900 text-white"
        onClick={(e) => {
          analyticProductQuantityIncrease({ productName: props.product.title });
          addToCartHandler();
          e.stopPropagation();
        }}
      >
        <PlusIcon className="h-4 w-4" />
      </div>
      <span className={'text-sm'}>{props.pickedProduct.quantity}</span>
      <div
        className="flex h-4 w-4 cursor-pointer items-center justify-center rounded-full bg-gray-900 text-white"
        onClick={() => {
          analyticProductQuantityDecrease({ productName: props.product.title });
          deleteHandler(pickedProductID(props.pickedProduct));
        }}
      >
        <MinusIcon className="h-4 w-4" />
      </div>
    </div>
  );
};

export default CartButton;
