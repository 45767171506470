import { graphql } from '../../../graphql/types';
import { useQuery } from '@apollo/client';

const QUERY = graphql(`
  query storefront_getTableReservationAvailability($branchId: ID!, $at: DateTime!) {
    storefront_getTableReservationAvailability(branchId: $branchId, at: $at) {
      slots {
        startTime
        spotsOpen
        spotsTotal
      }
    }
  }
`);

export default function useGetReservationAvailability(branchId: string, at: string) {
  return useQuery(QUERY, {
    variables: {
      branchId,
      at,
    },
  });
}
