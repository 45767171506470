import { Outlet } from 'react-router-dom';
import FooterBar from '@app/components/footer-bar.tsx';

export function Layout(props: { classNames: string }) {
  return (
    <div className="">
      <Outlet />
      <FooterBar classNames={props.classNames} />
    </div>
  );
}
