import { useQuery } from '@apollo/client';
import { graphql } from '@app/graphql/types';

const QUERY = graphql(`
  query storefront_getAppointmentAvailability($branchId: ID!, $at: DateTime!) {
    storefront_getAppointmentAvailability(branchId: $branchId, at: $at) {
      slots {
        startTime
        spotsOpen
        spotsTotal
      }
    }
  }
`);

export default function useGetAppointmentAvailability(branchId: string, at: string) {
  return useQuery(QUERY, {
    variables: {
      branchId,
      at,
    },
  });
}
