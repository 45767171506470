import { useAppointmentInfo } from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { useTranslate } from '@tolgee/react';
import { useEffect, useState } from 'react';
import { Moment } from 'moment';
import moment from 'moment-timezone';
import { useAppointmentBranchConfiguration } from '@app/page/appointment/appointment-create/logic/use-get-appointment-branch-configuration.ts';
import { getFragmentData } from '@app/graphql/types';
import { BranchFragment } from '@app/page/online-order/model/branch-fragment.ts';
import classNames from 'classnames';
import { AppointmentTimeSlot } from '@app/graphql/types/graphql.ts';
import Loading from '@app/components/loading.tsx';
import useGetAppointmentAvailability from '@app/page/appointment/appointment-create/logic/use-get-appointment-availability.ts';

export default function NailAppointmentDateInput(props: {
  branchId: string;
  selectedProductData: { id: string; duration: number };
}) {
  const { t } = useTranslate();

  const { data: configData } = useAppointmentBranchConfiguration(props.branchId);
  const startTime = useAppointmentInfo((state) => state.appointmentInput.startTime);

  const setAppointmentInput = useAppointmentInfo((state) => state.setAppointmentInput);

  const [date, setDate] = useState<Moment | null>(null);

  const branch = getFragmentData(BranchFragment, configData?.storefront_appointmentBranchConfiguration.branch);
  const timezone = branch?.company.settings.timezone ?? 'Europe/Berlin';

  useEffect(() => {
    if (!startTime) {
      setDate(moment.tz(timezone));
      return;
    }
    setDate(moment.tz(startTime, timezone));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configData, timezone]);

  const handleFunction = (value: string) => {
    setAppointmentInput({ startTime: value });
  };

  useEffect(() => {
    if (!date || !timezone || !startTime) {
      handleFunction(moment.tz(timezone).format('YYYY-MM-DDTHH:mm'));
      return;
    }

    const dateString = date.format('YYYY-MM-DD');
    const currentHourValue = moment.tz(startTime, timezone).format('HH:mm');

    handleFunction(`${dateString}T${currentHourValue}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  if (!date) {
    return null;
  }

  const handleDateIncrementAndDecrement = (action: string) => {
    switch (action) {
      case 'increment':
        setDate(date.clone().add(1, 'days')); // Tạo bản sao trước khi thêm 1 ngày
        return;
      case 'decrement':
        if (!isPreviousValid(date, timezone)) {
          setDate(date.clone().subtract(1, 'day')); // Tạo bản sao trước khi trừ 1 ngày
        }
        return;
      case 'today':
        setDate(moment.tz(timezone)); // Không cần clone vì bạn đang tạo một Moment mới
        return;
      default:
        throw new Error('Wrong action!!');
    }
  };

  return (
    <div>
      <div>
        <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.date.label', 'Date')}
        </label>

        <input
          type="date"
          className="w-full rounded border border-gray-300 px-3 py-2"
          value={date.format('YYYY-MM-DD')}
          onChange={(e) => {
            setDate(moment(e.target.value, 'YYYY-MM-DD'));
          }}
          min={moment.tz(timezone).format('YYYY-MM-DD')}
        />
      </div>

      <div className="grid grid-cols-3 gap-2 pt-2">
        <div
          onClick={() => {
            handleDateIncrementAndDecrement('decrement');
          }}
          className={classNames(
            'flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
            {
              'bg-gray-100 hover:bg-gray-100': isPreviousValid(date, timezone),
            }
          )}
        >
          {t('reservation.create-form.form-input.date.button.previous', 'Previous')}
        </div>
        <div
          onClick={() => {
            handleDateIncrementAndDecrement('today');
          }}
          className={classNames(
            'flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-indigo-400',
            {
              'bg-indigo-600 text-white': date.isSame(moment.tz(timezone), 'date'),
            }
          )}
        >
          {t('reservation.create-form.form-input.date.button.today', 'Today')}
        </div>

        <div
          onClick={() => {
            handleDateIncrementAndDecrement('increment');
          }}
          className="flex cursor-pointer items-center justify-center rounded px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          {t('reservation.create-form.form-input.date.button.next', 'Next')}
        </div>
      </div>

      <div className="pt-6">
        <label className="mb-1 block text-sm font-medium leading-6 text-gray-900">
          {t('reservation.create-form.form-input.date.time', 'Time')}
        </label>
        <TimeSlots
          timezone={timezone}
          branchId={props.branchId}
          date={date}
          currentValue={startTime}
          selectedProductData={props.selectedProductData}
          handleFunction={handleFunction}
        />
      </div>
    </div>
  );
}

function TimeSlots(props: {
  timezone: string;
  branchId: string;
  date: Moment;
  handleFunction: (value: string) => void;
  currentValue: string;
  selectedProductData: { id: string; duration: number };
}) {
  const { t } = useTranslate();
  const { data, loading } = useGetAppointmentAvailability(
    props.branchId,
    props.date.set('hour', 6).format('YYYY-MM-DDTHH:mm')
  );
  const [timeSlots, setTimeSlots] = useState<AppointmentTimeSlot[] | undefined>(undefined);

  useEffect(() => {
    setTimeSlots(data?.storefront_getAppointmentAvailability.slots.map((item) => item));
  }, [data, setTimeSlots]);

  if (loading) {
    return <Loading />;
  }

  if (!timeSlots || timeSlots.length == 0) {
    return (
      <div className="flex w-full items-center justify-center p-4 text-sm text-gray-400">
        {t('storefront-rerservation.create.service-and-time.form-input.date.no-time', 'No available time')}
      </div>
    );
  }

  const handleClick = (value: string) => {
    const currentDateValue = moment.tz(props.currentValue, props.timezone).format('YYYY-MM-DD');
    const hourString = moment.tz(value, props.timezone).format('HH:mm');
    props.handleFunction(`${currentDateValue}T${hourString}`);
  };

  return (
    <div className="">
      <div className="grid max-h-80 grid-cols-3 gap-2 overflow-scroll rounded-xl bg-neutral-200 p-2 py-4 [&::-webkit-scrollbar]:w-0">
        {timeSlots.map((time) => {
          return (
            <div
              key={time.startTime}
              onClick={() => {
                if (time.spotsOpen > 0) {
                  handleClick(time.startTime);
                }
              }}
              className={classNames(
                'flex cursor-pointer items-center justify-center gap-x-1 rounded-lg px-2 py-2 text-sm font-semibold text-gray-900 hover:bg-indigo-600 hover:text-white',
                {
                  'opacity-50 hover:bg-neutral-200 hover:text-gray-900': time.spotsOpen <= 0,
                },
                {
                  'bg-indigo-600 text-white':
                    moment.tz(props.currentValue, props.timezone).format('HH:mm') ==
                    moment.tz(time.startTime, props.timezone).format('HH:mm'),
                }
              )}
            >
              <span>{moment.tz(time.startTime, props.timezone).format('HH:mm')}</span>
              {time.spotsOpen <= 0 && <span className="font-light italic">{t('reservation.full-slot', '(Full)')}</span>}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function isPreviousValid(date: Moment, timezone: string) {
  return moment.tz(timezone).isSame(date, 'date');
}
