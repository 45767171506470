import { createSearchParams, useNavigate } from 'react-router-dom';
import CustomerData from './customer-data';
import {
  Storefront_TableReservationItemFragment,
  TableReservationConfirmationStatus,
} from '@app/graphql/types/graphql.ts';
import { useState } from 'react';
import { ClockIcon, CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { logEvent } from 'firebase/analytics';
import { analytics } from '@app/utils/analytics.ts';
import { useTranslate } from '@tolgee/react';
import CancelReasonReservationButton from '@app/page/table-reservation-detail/component/cancel-reason-reservation-button.tsx';

export default function ReservationProcess({
  reservation,
  data,
  branchID,
}: {
  reservation: Storefront_TableReservationItemFragment | null | undefined;
  data: TableReservationConfirmationStatus | undefined;
  branchID: string | null;
}) {
  const navigate = useNavigate();

  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const { t } = useTranslate();
  return (
    <div className="border-1 flex flex-col px-8 py-8 md:mt-16 md:border md:px-24 md:py-16 md:shadow">
      {branchID && (
        <div className="flex items-center justify-between border-b-4 border-gray-300 px-6 pb-8 pt-0">
          <div></div>

          <div className="text-right text-sm italic text-gray-600">
            <p className="text-xl">{reservation?.branch.name ?? ''}</p>
            <p>{`${reservation?.branch.streetAddress ?? ''}, ${reservation?.branch.postalCode ?? ''} ${reservation?.branch.addressLocality ?? ''}`}</p>
          </div>
        </div>
      )}
      {data == TableReservationConfirmationStatus.Waiting && (
        <div>
          <div className="text-wrap bg-white px-2 py-6">
            <ClockIcon className="mx-auto my-6 h-20 w-20 text-yellow-500" />

            <div className="text-center">
              <h3 className="text-center text-lg font-semibold text-gray-900 md:text-2xl">
                {t('reservation.detail.header.waiting.title', 'Your reservation is being processed !')}
              </h3>
              <p className="my-2 text-gray-600">
                {t('reservation.detail.header.waiting.description', 'Wait for a few minutes')}
              </p>
            </div>
          </div>
        </div>
      )}

      {data == TableReservationConfirmationStatus.Confirmed && (
        <div className="bg-white px-2 py-6 md:mx-auto">
          <CheckCircleIcon className="mx-auto my-6 h-20 w-20 text-green-500" />

          <div className="text-center">
            <h3 className="text-center text-lg font-semibold text-gray-900 md:text-2xl">
              {t('reservation.detail.header.confirmed.title', 'Your reservation is accepted')}
            </h3>
            <p className="my-2 text-gray-600">
              {t('reservation.detail.header.confirmed.description', 'Thank you for waiting. Have a great day!')}
            </p>
          </div>
        </div>
      )}

      {data == TableReservationConfirmationStatus.Cancelled && (
        <div className="bg-white px-2 py-6 md:mx-auto">
          <XCircleIcon className="mx-auto my-6 h-20 w-20 text-red-500" />

          <div className="text-center">
            <h3 className="text-center text-lg font-semibold text-gray-900 md:text-2xl">
              {t('reservation.detail.header.cancelled.title', 'Your reservation is denied')}
            </h3>
            <p className="my-2 text-gray-600">
              {t(
                'reservation.detail.header.cancelled.description',
                "Sorry, we couldn't accept your reservation. Have a great day!"
              )}
            </p>
          </div>
        </div>
      )}

      {/* Customer data */}

      <CustomerData reservation={reservation} />

      {/* Divider */}
      <hr className="my-8" />

      {/* Action */}

      <div className="flex w-full flex-col justify-center gap-4">
        <div className="flex gap-4">
          {reservation?.confirmationStatus != TableReservationConfirmationStatus.Cancelled && !cancelConfirmation && (
            <button
              onClick={() => {
                setCancelConfirmation(true);
              }}
              className="flex-grow rounded-md border-2 border-red-500 bg-white px-2 py-1.5 font-semibold text-red-500 hover:bg-red-500 hover:text-white"
            >
              {t('reservation.detail.action.cancel.cancel-booking', 'Cancel booking')}
            </button>
          )}

          {reservation?.confirmationStatus != TableReservationConfirmationStatus.Cancelled && cancelConfirmation && (
            <>
              <button
                onClick={() => {
                  logEvent(analytics, 'table_reservation_cancel_confirm_no', {
                    companyID: reservation?.branch.companyId ?? '',
                    branchID: reservation?.branch.id ?? '',
                  });
                  setCancelConfirmation(false);
                }}
                className="flex-1 rounded-md border-2 border-indigo-500 bg-white px-2 py-1.5 font-semibold text-indigo-500 hover:bg-indigo-500 hover:text-white"
              >
                {t('reservation.detail.action.cancel.confirm-no-cancel', 'No, i still booking')}
              </button>

              <CancelReasonReservationButton
                onClick={() => {
                  logEvent(analytics, 'table_reservation_cancel_confirm_yes', {
                    companyID: reservation?.branch.companyId ?? '',
                    branchID: reservation?.branch.id ?? '',
                  });
                }}
                reservation={reservation}
              />

              {/*<button*/}
              {/*  // onClick={() => {*/}
              {/*  //   logEvent(analytics, 'table_reservation_cancel_confirm_yes', {*/}
              {/*  //     companyID: reservation?.branch.companyId ?? '',*/}
              {/*  //     branchID: reservation?.branch.id ?? '',*/}
              {/*  //   });*/}
              {/*  //   void cancel({*/}
              {/*  //     variables: {*/}
              {/*  //       id: reservation?.id ?? '',*/}
              {/*  //     },*/}
              {/*  //   }).then(() => {*/}
              {/*  //     window.location.reload();*/}
              {/*  //   });*/}
              {/*  // }}*/}

              {/*  className="flex-1 rounded-md bg-red-500 px-2 py-1.5 font-semibold text-white hover:bg-red-600"*/}
              {/*>*/}
              {/*  {t('reservation.detail.action.cancel.confirm-cancel', 'Yes, cancel')}*/}
              {/*</button>*/}
            </>
          )}
        </div>

        <button
          onClick={() => {
            logEvent(analytics, 'table_reservation_make_new', {
              companyID: reservation?.branch.companyId ?? '',
              branchID: reservation?.branch.id ?? '',
            });
            navigate({
              pathname: '/table-reservation',
              search: createSearchParams({
                companyID: reservation?.branch.companyId ?? '',
                branchID: reservation?.branch.id ?? '',
              }).toString(),
            });
          }}
          className="rounded-md bg-indigo-500 px-2 py-1.5 font-semibold text-white hover:bg-indigo-600"
        >
          {t('reservation.detail.action.cancel.make-new-reservation', 'Make new reservation')}
        </button>
      </div>
    </div>
  );
}
