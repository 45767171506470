import { useSearchParams } from 'react-router-dom';
import Card from '@app/components/card/card.tsx';
import CardHeader from '@app/components/card/card-header.tsx';
import CardContent from '@app/components/card/card-content.tsx';
import { ServiceProductItemFragment } from '@app/graphql/types/graphql.ts';
import SelectedProduct from '@app/page/appointment/appointment-create/component/selected-product.tsx';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import {
  AppointmentMonitor,
  useAppointmentInfo,
  validateTimeInput,
} from '@app/page/appointment/appointment-create/logic/use-appointment-info.ts';
import { LanguageSelector } from '@app/page/table-reservation/component/table-reservation-create-form.tsx';
import { useTranslate } from '@tolgee/react';
import NailAppointmentDateInput from '@app/page/appointment/appointment-create/component/nail-appointment-date-input.tsx';

export default function AppointmentServiceAndTime(props: {
  selectedProduct: ServiceProductItemFragment | null | undefined;
}) {
  const { selectedProduct } = props;
  const { t } = useTranslate();

  const [searchParams] = useSearchParams();
  const branchID = searchParams.get('branchID') ?? '';

  const { setAppointmentMonitor, appointmentInput } = useAppointmentInfo((state) => state);

  const handleBackButtonClick = () => {
    setAppointmentMonitor(AppointmentMonitor.start);
  };

  const handleContinue = () => {
    void validateTimeInput(appointmentInput);
    setAppointmentMonitor(AppointmentMonitor.contactInfo);
  };

  if (!selectedProduct) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-center bg-gray-100 sm:justify-center">
      <Card className="w-full sm:mt-24 md:mt-12 lg:mx-auto lg:mt-24 lg:w-[850px]">
        <CardHeader
          title={t('storefront-appointment.create.service-and-time.header', 'Back to bussiness page')}
          withBackButton={true}
          handleBackButtonClick={handleBackButtonClick}
        >
          <LanguageSelector />
        </CardHeader>

        <CardContent>
          <div className="m-2 flex flex-col gap-6">
            <SelectedProduct selectedProduct={selectedProduct} />
            {/*<SelectedMember selectedProduct={selectedProduct} />*/}

            <NailAppointmentDateInput
              branchId={branchID}
              selectedProductData={{
                id: selectedProduct.id,
                duration: selectedProduct.appointmentMenuProductData.duration,
              }}
            />
          </div>
        </CardContent>

        <CardFooter>
          <PrimaryButton
            disabled={appointmentInput.startTime == ''}
            onClick={handleContinue}
            className="bg-indigo-600 px-8 hover:bg-indigo-400"
          >
            {t('storefront-appointment.create.service-and-time.button.continue', 'Continue')}
          </PrimaryButton>
        </CardFooter>
      </Card>
    </div>
  );
}
