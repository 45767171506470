import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { MenuProductItemFragment, MenuSectionItemFragment } from '@app/graphql/types/graphql.ts';
import { useState } from 'react';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { naturalCompare } from '@app/utils/natural-compare.ts';
import { ArrowsUpDownIcon, CheckCircleIcon } from '@heroicons/react/16/solid';
import { SortType } from '@app/page/online-order/product-picker/utils/sort-type.ts';

const sortTypeList: SortType[] = [SortType.INCREASE, SortType.DECREASE, SortType.NATURAL];

export default function FunctionalBar(props: {
  sections: readonly MenuSectionItemFragment[];
  products: readonly MenuProductItemFragment[];
  searchProduct: (searchString: string) => void;
  setSortType: (sortType: SortType) => void;
  sortType: SortType;
}) {
  const sections = [...props.sections].sort((a, b) => naturalCompare(a.code, b.code));
  const { t } = useTranslate();
  const [currentSection, setCurrentSection] = useState<string>(
    t('online-order.list.header.category.placeholder', 'Category')
  );
  const [expandSearch, setExpandSearch] = useState<boolean>(false);
  return (
    <div className="grid grid-cols-1 gap-2 rounded-xl rounded-b-none border-b bg-white p-4 ring-gray-500/50 md:grid-cols-2">
      <Menu as="div" className={'relative w-full'}>
        <div>
          <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-3xl bg-gray-50 px-3 py-2.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {currentSection}
            <ChevronUpDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
          </MenuButton>
        </div>
        <MenuItems
          transition
          className="focus:outline-none:scale-95 absolute mt-2 max-h-96 w-full origin-top-left divide-y divide-gray-100 overflow-scroll rounded-2xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          {sections.map((section) => {
            const products = props.products.filter((product) => product.sectionId == section.id);
            if (products.length == 0) {
              return null;
            }
            return (
              <div className="py-1" key={section.id}>
                <MenuItem>
                  <a
                    key={section.id}
                    href={`#${section.id}`}
                    className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                    onClick={() => {
                      setCurrentSection(section.name);
                    }}
                  >
                    {section.name}
                  </a>
                </MenuItem>
              </div>
            );
          })}
        </MenuItems>
      </Menu>

      <div className="flex justify-between gap-x-2">
        <div className={classNames(expandSearch ? 'flex w-full items-center justify-between gap-2' : 'grow')}>
          <input
            type={'search'}
            className={
              'block w-full rounded-3xl border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:pl-1 placeholder:text-sm placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
            }
            name={'search'}
            placeholder={t('online-order.list.header.search.placeholder', 'Search')}
            onClick={() => {
              if (screen.width < 640) {
                setExpandSearch(true);
              }
            }}
            onChange={(e) => {
              props.searchProduct(e.target.value);
            }}
          />

          <div
            onClick={() => {
              setExpandSearch(false);
            }}
            className={classNames('font-semibold hover:text-gray-600', !expandSearch && 'hidden')}
          >
            {t('online-order.list.header.search.button.cancel', 'Cancel')}
          </div>
        </div>

        <Menu className={classNames(expandSearch ? 'hidden' : 'relative')} as="div">
          <div>
            <MenuButton className="inline-flex w-full justify-between gap-x-1.5 rounded-3xl bg-gray-50 px-3 py-2.5 text-sm font-medium text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
              <ArrowsUpDownIcon className="h-6 w-6" />
              <p>{t('online-order.picked-list.sort-button', 'Sort')}</p>
            </MenuButton>
          </div>
          <MenuItems
            transition
            anchor={'bottom end'}
            className="absolute z-10 mt-2 max-h-96 origin-top-left divide-y divide-gray-100 overflow-scroll rounded-2xl bg-white shadow shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
          >
            {sortTypeList.map((sortType, index) => {
              return (
                <div className="" key={index}>
                  <MenuItem>
                    <div
                      onClick={() => {
                        props.setSortType(sortType);
                      }}
                      className="flex items-center justify-start gap-x-2 px-4 py-3 hover:bg-gray-100"
                    >
                      <div className="h-4 w-4">
                        {props.sortType == sortType && <CheckCircleIcon className="h-4 w-4" />}
                      </div>
                      {t(`online-order.product-list.sort.${sortType}`)}
                    </div>
                  </MenuItem>
                </div>
              );
            })}
          </MenuItems>
        </Menu>
      </div>
    </div>
  );
}
