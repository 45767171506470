import Card from '@app/components/card/card.tsx';
import useGetAppointment from '@app/page/appointment/appointment-track/logic/use-get-appointment.ts';
import { useNavigate, useParams } from 'react-router-dom';
import CardContent from '@app/components/card/card-content.tsx';
import { formatRawCurrency } from '@app/components/price/currency-formatter.ts';
import moment from 'moment-timezone';
import CardFooter from '@app/components/card/card-footer.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import useAppointmentStatusUpdateSubscribe from '@app/page/appointment/appointment-track/logic/use-apppointment-status-update-subscription.ts';
import { useEffect } from 'react';
import { AppointmentConfirmationStatus } from '@app/graphql/types/graphql.ts';
import CancelButtonDialog from '@app/page/appointment/appointment-track/component/cancel-button-dialog.tsx';
import { useTranslate } from '@tolgee/react';
import Loading from '@app/components/loading.tsx';
import AppointmentStatusNotification from '@app/page/appointment/appointment-track/component/appointment-status-notification.tsx';
import { AppointmentStatusNotificationType } from '@app/page/appointment/appointment-track/component/appointment-status-notification-type.ts';

export default function AppointmentTrack() {
  const appointmentId = useParams().appointmentId ?? '';
  const navigate = useNavigate();
  const { t } = useTranslate();

  const { data, refetch, loading } = useGetAppointment(appointmentId);
  const { data: _updateData } = useAppointmentStatusUpdateSubscribe(appointmentId);

  const productData = data?.storefront_appointment.products[0].pickedProduct.menuProduct;
  const timeData = Number(data?.storefront_appointment.startTime);
  const timezone = data?.storefront_appointment.branch?.company.settings.timezone ?? 'UTC';
  const clientData = data?.storefront_appointment.customerVariant;
  const currencyData = formatRawCurrency(
    data?.storefront_appointment.products[0].pickedProduct.configurations[0].price ?? 0,
    data?.storefront_appointment.branch?.company.settings.currency ?? 'EUR'
  );

  useEffect(() => {
    if (!_updateData) {
      return;
    }
    void refetch();
  }, [_updateData, refetch]);

  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return null;
  }

  return (
    <div className="flex w-full flex-col items-center bg-gray-100 sm:justify-center">
      <div className="flex w-full flex-col gap-y-4 sm:mt-8 md:mt-16 lg:mx-auto lg:mt-24 lg:w-[850px]">
        <AppointmentStatusNotification
          state={data.storefront_appointment.confirmationStatus}
          phone={data.storefront_appointment.branch?.contactPhone ?? ''}
          time={moment(timeData).tz(timezone).format('ddd DD MMM, HH:mm')}
          mode={AppointmentStatusNotificationType.CARD}
        />

        <AppointmentStatusNotification
          state={data.storefront_appointment.confirmationStatus}
          phone={data.storefront_appointment.branch?.contactPhone ?? ''}
          time={moment(timeData).tz(timezone).format('ddd DD MMM, HH:mm')}
          mode={AppointmentStatusNotificationType.POP_UP}
        />
        <Card>
          <CardContent>
            <div className="flex flex-col gap-y-8 px-2">
              {/*Selected service*/}
              <div className="-mx-2">
                <p className="mb-1 ml-3 mt-2 text-sm font-medium text-gray-700">
                  {t('storefront-appointment.create.track.form-input.selected-service.title', 'Selected service')}
                </p>

                <div
                  key={productData?.id}
                  className="flex w-full items-center justify-between rounded-xl bg-stone-100 px-4 py-3 hover:bg-stone-200"
                >
                  <div className="space-y-2">
                    <p className="mb-0.5 text-sm">{productData?.title}</p>
                    <p className="line-clamp-2 max-w-xl break-words text-xs text-gray-700">
                      {productData?.description}
                    </p>
                    <p className="text-xs text-gray-500">
                      {productData?.appointmentMenuProductData.duration}{' '}
                      {t('storefront-appointment.create.minutes', 'Minutes')}
                    </p>
                  </div>

                  <div>
                    <p className="text-base text-gray-500">{currencyData.value + currencyData.symbol}</p>
                  </div>
                </div>
              </div>

              {/*Time*/}
              <div className="grid grid-cols-2 gap-4 border-b pb-4">
                <div className="col-span-2 space-y-1 sm:col-span-1">
                  <p className="text-sm">{t('storefront-appointment.create.track.form-input.date.title', 'Date')}</p>
                  <p>{moment(timeData).tz(timezone).format('ddd DD MMM')}</p>
                </div>
                <div className="col-span-2 space-y-1 sm:col-span-1">
                  <p className="text-sm">{t('storefront-appointment.create.track.form-input.time.title', 'Time')}</p>
                  <p>{moment(timeData).tz(timezone).format('HH:mm')}</p>
                </div>
              </div>

              {/*Client Information*/}
              <div className="grid grid-cols-1 gap-10 sm:grid-cols-2">
                <ClientInfoItem
                  label={t('storefront-appointment.create.track.form-input.name.title', 'Name')}
                  value={clientData?.name ?? ''}
                  className={'sm:col-span-2'}
                />
                <ClientInfoItem
                  label={t('storefront-appointment.create.track.form-input.email.title', 'Email')}
                  value={clientData?.email ?? ''}
                />
                <ClientInfoItem
                  label={t('storefront-appointment.create.track.form-input.phone.title', 'Phone')}
                  value={clientData?.phone ?? ''}
                />{' '}
                {data.storefront_appointment.message && (
                  <ClientInfoItem
                    label={t('storefront-appointment.create.track.form-input.message.title', 'Message')}
                    value={data.storefront_appointment.message ?? ''}
                    className={'sm:col-span-2'}
                  />
                )}
              </div>
            </div>
          </CardContent>

          <CardFooter>
            <div className="flex w-full flex-col items-center justify-between gap-y-4 sm:flex-row">
              {!(data.storefront_appointment.confirmationStatus == AppointmentConfirmationStatus.Cancelled) && (
                <CancelButtonDialog />
              )}
              <PrimaryButton
                onClick={() => {
                  navigate(`/appointment/create?branchID=${data.storefront_appointment.branchId}`);
                }}
                className="w-full bg-indigo-600 px-6 hover:bg-indigo-400 sm:w-auto"
              >
                {t('storefront-appointment.create.track.button.another-booking', 'Another Booking')}
              </PrimaryButton>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
}

function ClientInfoItem(props: { label: string; value: string; className?: string }) {
  return (
    <div className={props.className}>
      <p className="mb-2 text-sm">{props.label}</p>
      <p className="text-md font-light">{props.value}</p>
    </div>
  );
}
