/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    fragment Storefront_TableReservationItem on Storefront_TableReservation {\n        id\n        confirmationStatus\n        phone\n        email\n        startTime\n        endTime\n        seats\n        guestName\n        message\n        timezone\n        flexTime\n        \n        branch {\n            id\n            name\n            streetAddress\n            postalCode\n            addressCountry\n            addressLocality\n            addressRegion\n            companyId\n        }\n    }\n": types.Storefront_TableReservationItemFragmentDoc,
    "\n  mutation storefront_CreateAppointment(\n    $branchID: ID!\n    $input: Storefront_CreateAppointmentInput!\n    $pickedProductInput: [Storefront_PickedProductInput!]!\n  ) {\n    storefront_createAppointment(branchID: $branchID, input: $input, pickedProductInput: $pickedProductInput) {\n      id\n    }\n  }\n": types.Storefront_CreateAppointmentDocument,
    "\n  query storefront_getAppointmentAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getAppointmentAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n": types.Storefront_GetAppointmentAvailabilityDocument,
    "\n  query storefront_appointmentBranchConfiguration($branchId: ID!) {\n    storefront_appointmentBranchConfiguration(branchId: $branchId) {\n      hourBlocking\n      appointmentLeadMinutes\n      appointmentTraitMinutes\n      branch {\n        ...BranchItem\n      }\n      menu {\n        ...ServiceItem\n      }\n    }\n  }\n": types.Storefront_AppointmentBranchConfigurationDocument,
    "\n    query storefront_openingTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n        storefront_openingTimes(branchID: $branchId, filter: $filter) {\n            id\n            dayOfWeek\n            openTime\n            closeTime\n        }\n    }\n": types.Storefront_OpeningTimesDocument,
    "\n  query storefront_holidays($branchId: ID!) {\n    storefront_holidays(branchId: $branchId) {\n      id\n      description\n      startTime\n      endTime\n    }\n  }\n": types.Storefront_HolidaysDocument,
    "\n  query storefront_isOpenNow($branchID: ID!, $service: [OpeningTimeServiceType!]!) {\n    storefront_isOpenNow(branchID: $branchID, service: $service)\n  }\n": types.Storefront_IsOpenNowDocument,
    "\n  subscription Storefront_AppointmentStatusUpdateSubscribe($id: ID!) {\n    storefront_appointmentStatusUpdateSubscribe(id: $id) {\n      event\n      appointment {\n        id\n        confirmationStatus\n      }\n    }\n  }\n": types.Storefront_AppointmentStatusUpdateSubscribeDocument,
    "\n  mutation storefront_cancelAppointment($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelAppointment(id: $id, reason: $reason) {\n      id\n    }\n  }\n": types.Storefront_CancelAppointmentDocument,
    "\n  query storefront_getAppointment($id: ID!) {\n    storefront_appointment(id: $id) {\n      id\n      branchId\n      startTime\n      message\n      confirmationStatus\n      branch {\n        contactPhone\n        company {\n          settings {\n            currency\n            timezone\n          }\n        }\n      }\n      customerVariant {\n        name\n        email\n        phone\n      }\n      products {\n        id\n        pickedProduct {\n          id\n          title\n          code\n          description\n          menuProduct {\n            id\n            title\n            code\n            description\n            appointmentMenuProductData {\n              duration\n            }\n          }\n          configurations {\n            title\n            value\n            price\n          }\n        }\n      }\n    }\n  }\n": types.Storefront_GetAppointmentDocument,
    "\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      menuId\n      url\n      menu {\n        id\n        title\n        companyId\n        sections {\n          id\n          name\n          description\n        }\n        menuProducts {\n          id\n          title\n          description\n          images\n          ingredients\n          section {\n            id\n            name\n            description\n          }\n          configurations {\n            id\n            type\n            values {\n              price\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n": types.Get_Menu_PreviewDocument,
    "\n  query GetCompany($id: ID!) {\n    storefront_company(id: $id) {\n      id\n      name\n      logo\n      settings {\n        currency\n        timezone\n        customerDefaultLanguage\n      }\n    }\n  }\n": types.GetCompanyDocument,
    "\n  query StoreFrontBanners($companyID: String!, $type: String!) {\n    storefront_banners(companyId: $companyID, type: $type) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          foregroundColor\n          backgroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n": types.StoreFrontBannersDocument,
    "\n  query StoreFrontBranch($branchID: ID!) {\n    storefront_branch(id: $branchID) {\n      name\n      website\n      timezone\n      streetAddress\n      addressCountry\n      addressLocality\n      addressRegion\n      postalCode\n      companyId\n    }\n  }\n": types.StoreFrontBranchDocument,
    "\n    mutation ChangeUserStateOfOnlineOrder($onlineOrderID: ID!, $state: OnlineOrderUserState!) {\n        storefront_changeUserState(onlineOrderID: $onlineOrderID, state: $state)\n    }\n": types.ChangeUserStateOfOnlineOrderDocument,
    "\n  query OnlineOrderDetailPage($id: ID!) {\n    storefront_onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    storefront_onlineOrderBranchConfigurationByOrderID(id: $id) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_getOnlineOrderAvailability(onlineOrderId: $id) {\n      ...OnlineOrderAvailabilityItem\n    }\n  }\n": types.OnlineOrderDetailPageDocument,
    "\n    mutation ModifyOnlineOrderCart($onlineOrderProductID: ID!, $amount: Int!) {\n        storefront_changeProductAmount(onlineOrderProductID: $onlineOrderProductID, amount: $amount)\n    }\n": types.ModifyOnlineOrderCartDocument,
    "\n    subscription OnUpdateOnlineOrder($id: ID!) {\n        storefront_onUpdateOnlineOrder(id: $id) {\n            onlineOrderID\n            timestamp\n        }\n    }\n": types.OnUpdateOnlineOrderDocument,
    "\n    mutation OnlineOrderFillPersonalData($onlineOrderID: ID!, $input: UserContactInfoInput!) {\n        storefront_userFillsContactInfo(onlineOrderID: $onlineOrderID, input: $input)\n    }\n": types.OnlineOrderFillPersonalDataDocument,
    "\n  query storefront_isOnlineOrderOpen($branchID: ID!) {\n    storefront_isOnlineOrderOpen(branchID: $branchID) {\n      isDeliveryAvailable\n      isPickupAvailable\n    }\n  }\n": types.Storefront_IsOnlineOrderOpenDocument,
    "\n  fragment ServiceItem on ServiceMenu {\n    id\n    title\n    menuProducts {\n      ...ServiceProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n": types.ServiceItemFragmentDoc,
    "\n    fragment ServiceProductItem on NailAppointmentMenuProduct {\n        id\n        title\n        code\n        description\n        images\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            ...MenuProductConfigurationItem\n        }\n\n        appointmentMenuProductData {\n            menuProductId\n            duration\n            nailAppointmentMenuProductTeams {\n                id\n                team {\n                    id\n                    teamMember {\n                        id\n                        user {\n                            firstName\n                            lastName\n                        }\n                    }\n                }\n            }\n        }\n        menuId\n        sectionId\n    }\n": types.ServiceProductItemFragmentDoc,
    "\n  fragment BranchItem on StoreFront_Branch {\n    id\n    name\n    website\n\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    contactPhone\n    contactEmail\n\n    openingTimes {\n      service\n      closeTime\n      openTime\n      dayOfWeek\n    }\n\n    company {\n      id\n      logo\n      name\n      hubPage {\n        banner\n      }\n      settings {\n        currency\n        customerDefaultLanguage\n        timezone\n      }\n    }\n  }\n": types.BranchItemFragmentDoc,
    "\n  fragment MenuItem on Storefront_Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n": types.MenuItemFragmentDoc,
    "\n  fragment MenuProductItem on Storefront_MenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    allergics {\n      code\n      title\n    }\n    menuId\n    sectionId\n\n    labels\n  }\n": types.MenuProductItemFragmentDoc,
    "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n": types.MenuProductConfigurationItemFragmentDoc,
    "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n": types.MenuProductConfigurationValueItemFragmentDoc,
    "\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n    enabled\n    timeRule{\n      __typename\n      ... on MenuSectionAlwaysTimeRule {\n        type\n      }\n    \n      __typename\n      ... on MenuSectionRangeTimeRule {\n        type\n        start\n        end\n        daysOfWeek\n        isAllDays\n      }\n    }\n  }\n": types.MenuSectionItemFragmentDoc,
    "\n  fragment OnlineOrderAvailabilityItem on OnlineOrderAvailability {\n    pickup {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n    delivery {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n": types.OnlineOrderAvailabilityItemFragmentDoc,
    "\n  fragment OnlineOrderBranchConfigurationItem on Storefront_OnlineOrderBranchConfiguration {\n    branchID\n    menu {\n      ...MenuItem\n    }\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n    defaultPreparingTime\n    categoryStyle\n    pickupDiscountAmount\n    pickupDiscountType\n    deliveryDiscountAmount\n    deliveryDiscountType\n    autoDeliveryConfirm\n    autoPickupConfirm\n    pickupEnable\n    deliveryEnable\n    minimumOrderAmountForDelivery\n  }\n": types.OnlineOrderBranchConfigurationItemFragmentDoc,
    "\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n": types.OnlineOrderStateEventItemFragmentDoc,
    "\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n    discountable\n    menuProduct {\n      id\n      images\n      code\n      section {\n        name\n        id\n        code\n        description\n        timeRule {\n          __typename\n          ... on MenuSectionAlwaysTimeRule {\n            type\n          }\n          ... on MenuSectionRangeTimeRule {\n            type\n            start\n            end\n            isAllDays\n            daysOfWeek\n          }\n        }\n      }\n      discountable\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n": types.OnlineOrderProductsItemFragmentDoc,
    "\n  fragment OnlineOrderItem on Storefront_OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    expectedTime\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    deliveryDiscount\n    pickupDiscount\n    totalPrice\n\n    createdAt\n    updatedAt\n    userCreatedAt\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    branch {\n      ...BranchItem\n    }\n  }\n": types.OnlineOrderItemFragmentDoc,
    "\n    fragment OpeningTimeItem on OpeningTime {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n        service\n    }\n": types.OpeningTimeItemFragmentDoc,
    "\n  fragment ReservationAvailabilityItem on ReservationAvailability {\n    slots {\n      startTime\n      spotsOpen\n      spotsTotal\n    }\n  }\n": types.ReservationAvailabilityItemFragmentDoc,
    "\n   mutation CreateOnlineOrder($branchID: ID!, $input: OnlineOrderCreateInput!) {\n        storefront_createOnlineOrder(branchID: $branchID, input: $input) {\n            id\n        }\n   }\n": types.CreateOnlineOrderDocument,
    "\n  mutation OnlineOrderSetPickedProducts($onlineOrderID: ID!, $products: [OnlineOrderPickedProductInput!]!, $state: OnlineOrderUserState, $customerLanguage: String!) {\n    storefront_onlineOrderSetPickedProducts(onlineOrderID: $onlineOrderID, products: $products, userState: $state, customerLanguage: $customerLanguage)\n  }\n": types.OnlineOrderSetPickedProductsDocument,
    "\n  query ProductPickerPage($branchID: ID!) {\n    storefront_onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_branch(id: $branchID) {\n      ...BranchItem\n    }\n\n    storefront_isHolidayNow(branchId: $branchID)\n\n    storefront_isOnlineOrderAvailable(branchId: $branchID)\n  }\n": types.ProductPickerPageDocument,
    "\n  mutation Storefront_cancelReservation($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelReservation(id: $id, reason: $reason) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n": types.Storefront_CancelReservationDocument,
    "\n  mutation CreateTableBooking($branchId: ID!, $input: Storefront_TableReservationCreate!) {\n    storefront_createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n": types.CreateTableBookingDocument,
    "\n  query OpeningTimes($branchId: ID!) {\n    storefront_openingTimes(branchID: $branchId, filter: { service: TABLE_RESERVATION }) {\n      dayOfWeek\n      openTime\n      closeTime\n    }\n\n    storefront_tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      defaultBookingTimeDuration\n      hourBlocking\n      reservationLeadMinutes\n      reservationTraitMinutes\n      branch {\n        company {\n          settings {\n            timezone\n          }\n        }\n      }\n    }\n  }\n": types.OpeningTimesDocument,
    "\n  query ActiveTableReservationBranch($companyID: ID!) {\n    storefront_tableReservationBranches(companyId: $companyID) {\n      id\n      name\n      website\n      streetAddress\n      addressLocality\n      postalCode\n      tableReservationSetting {\n        seatLimit\n        phone\n        status\n        flexDuration\n        flexEnabled\n      }\n      company {\n        logo\n        settings {\n          customerDefaultLanguage\n          timezone\n        }\n      }\n    }\n  }\n": types.ActiveTableReservationBranchDocument,
    "\n  query storefront_getTableReservationAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getTableReservationAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n": types.Storefront_GetTableReservationAvailabilityDocument,
    "\n    subscription Storefront_tableReservationStatusUpdateSubscribe($id: ID!) {\n        storefront_tableReservationStatusUpdateSubscribe(id: $id) {\n            event\n            tableReservation {\n                id\n                confirmationStatus\n            }\n        }\n    }\n": types.Storefront_TableReservationStatusUpdateSubscribeDocument,
    "\n    query Storefront_tableReservationStatus($id: ID!) {\n        storefront_tableReservationStatus(id: $id) {\n            ...Storefront_TableReservationItem\n        }\n    }\n": types.Storefront_TableReservationStatusDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment Storefront_TableReservationItem on Storefront_TableReservation {\n        id\n        confirmationStatus\n        phone\n        email\n        startTime\n        endTime\n        seats\n        guestName\n        message\n        timezone\n        flexTime\n        \n        branch {\n            id\n            name\n            streetAddress\n            postalCode\n            addressCountry\n            addressLocality\n            addressRegion\n            companyId\n        }\n    }\n"): (typeof documents)["\n    fragment Storefront_TableReservationItem on Storefront_TableReservation {\n        id\n        confirmationStatus\n        phone\n        email\n        startTime\n        endTime\n        seats\n        guestName\n        message\n        timezone\n        flexTime\n        \n        branch {\n            id\n            name\n            streetAddress\n            postalCode\n            addressCountry\n            addressLocality\n            addressRegion\n            companyId\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation storefront_CreateAppointment(\n    $branchID: ID!\n    $input: Storefront_CreateAppointmentInput!\n    $pickedProductInput: [Storefront_PickedProductInput!]!\n  ) {\n    storefront_createAppointment(branchID: $branchID, input: $input, pickedProductInput: $pickedProductInput) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation storefront_CreateAppointment(\n    $branchID: ID!\n    $input: Storefront_CreateAppointmentInput!\n    $pickedProductInput: [Storefront_PickedProductInput!]!\n  ) {\n    storefront_createAppointment(branchID: $branchID, input: $input, pickedProductInput: $pickedProductInput) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_getAppointmentAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getAppointmentAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"): (typeof documents)["\n  query storefront_getAppointmentAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getAppointmentAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_appointmentBranchConfiguration($branchId: ID!) {\n    storefront_appointmentBranchConfiguration(branchId: $branchId) {\n      hourBlocking\n      appointmentLeadMinutes\n      appointmentTraitMinutes\n      branch {\n        ...BranchItem\n      }\n      menu {\n        ...ServiceItem\n      }\n    }\n  }\n"): (typeof documents)["\n  query storefront_appointmentBranchConfiguration($branchId: ID!) {\n    storefront_appointmentBranchConfiguration(branchId: $branchId) {\n      hourBlocking\n      appointmentLeadMinutes\n      appointmentTraitMinutes\n      branch {\n        ...BranchItem\n      }\n      menu {\n        ...ServiceItem\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query storefront_openingTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n        storefront_openingTimes(branchID: $branchId, filter: $filter) {\n            id\n            dayOfWeek\n            openTime\n            closeTime\n        }\n    }\n"): (typeof documents)["\n    query storefront_openingTimes($branchId: ID!, $filter: OpeningTimeFilter) {\n        storefront_openingTimes(branchID: $branchId, filter: $filter) {\n            id\n            dayOfWeek\n            openTime\n            closeTime\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_holidays($branchId: ID!) {\n    storefront_holidays(branchId: $branchId) {\n      id\n      description\n      startTime\n      endTime\n    }\n  }\n"): (typeof documents)["\n  query storefront_holidays($branchId: ID!) {\n    storefront_holidays(branchId: $branchId) {\n      id\n      description\n      startTime\n      endTime\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_isOpenNow($branchID: ID!, $service: [OpeningTimeServiceType!]!) {\n    storefront_isOpenNow(branchID: $branchID, service: $service)\n  }\n"): (typeof documents)["\n  query storefront_isOpenNow($branchID: ID!, $service: [OpeningTimeServiceType!]!) {\n    storefront_isOpenNow(branchID: $branchID, service: $service)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  subscription Storefront_AppointmentStatusUpdateSubscribe($id: ID!) {\n    storefront_appointmentStatusUpdateSubscribe(id: $id) {\n      event\n      appointment {\n        id\n        confirmationStatus\n      }\n    }\n  }\n"): (typeof documents)["\n  subscription Storefront_AppointmentStatusUpdateSubscribe($id: ID!) {\n    storefront_appointmentStatusUpdateSubscribe(id: $id) {\n      event\n      appointment {\n        id\n        confirmationStatus\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation storefront_cancelAppointment($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelAppointment(id: $id, reason: $reason) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation storefront_cancelAppointment($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelAppointment(id: $id, reason: $reason) {\n      id\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_getAppointment($id: ID!) {\n    storefront_appointment(id: $id) {\n      id\n      branchId\n      startTime\n      message\n      confirmationStatus\n      branch {\n        contactPhone\n        company {\n          settings {\n            currency\n            timezone\n          }\n        }\n      }\n      customerVariant {\n        name\n        email\n        phone\n      }\n      products {\n        id\n        pickedProduct {\n          id\n          title\n          code\n          description\n          menuProduct {\n            id\n            title\n            code\n            description\n            appointmentMenuProductData {\n              duration\n            }\n          }\n          configurations {\n            title\n            value\n            price\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query storefront_getAppointment($id: ID!) {\n    storefront_appointment(id: $id) {\n      id\n      branchId\n      startTime\n      message\n      confirmationStatus\n      branch {\n        contactPhone\n        company {\n          settings {\n            currency\n            timezone\n          }\n        }\n      }\n      customerVariant {\n        name\n        email\n        phone\n      }\n      products {\n        id\n        pickedProduct {\n          id\n          title\n          code\n          description\n          menuProduct {\n            id\n            title\n            code\n            description\n            appointmentMenuProductData {\n              duration\n            }\n          }\n          configurations {\n            title\n            value\n            price\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      menuId\n      url\n      menu {\n        id\n        title\n        companyId\n        sections {\n          id\n          name\n          description\n        }\n        menuProducts {\n          id\n          title\n          description\n          images\n          ingredients\n          section {\n            id\n            name\n            description\n          }\n          configurations {\n            id\n            type\n            values {\n              price\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GET_MENU_PREVIEW($companyID: ID!) {\n    menuPreview(companyId: $companyID) {\n      id\n      type\n      menuId\n      url\n      menu {\n        id\n        title\n        companyId\n        sections {\n          id\n          name\n          description\n        }\n        menuProducts {\n          id\n          title\n          description\n          images\n          ingredients\n          section {\n            id\n            name\n            description\n          }\n          configurations {\n            id\n            type\n            values {\n              price\n              name\n            }\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetCompany($id: ID!) {\n    storefront_company(id: $id) {\n      id\n      name\n      logo\n      settings {\n        currency\n        timezone\n        customerDefaultLanguage\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetCompany($id: ID!) {\n    storefront_company(id: $id) {\n      id\n      name\n      logo\n      settings {\n        currency\n        timezone\n        customerDefaultLanguage\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StoreFrontBanners($companyID: String!, $type: String!) {\n    storefront_banners(companyId: $companyID, type: $type) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          foregroundColor\n          backgroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query StoreFrontBanners($companyID: String!, $type: String!) {\n    storefront_banners(companyId: $companyID, type: $type) {\n      bannerId\n      title\n      viewCount\n      clickCount\n      enabled\n      createAt\n      updateAt\n      content {\n        __typename\n        ... on TopContent {\n          type\n          text\n          foregroundColor\n          backgroundColor\n          action\n        }\n        ... on OverlayContent {\n          type\n          image\n        }\n      }\n      timeRule {\n        __typename\n        ... on RangeTimeRule {\n          type\n          start\n          end\n        }\n        ... on AlwaysTimeRule {\n          type\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query StoreFrontBranch($branchID: ID!) {\n    storefront_branch(id: $branchID) {\n      name\n      website\n      timezone\n      streetAddress\n      addressCountry\n      addressLocality\n      addressRegion\n      postalCode\n      companyId\n    }\n  }\n"): (typeof documents)["\n  query StoreFrontBranch($branchID: ID!) {\n    storefront_branch(id: $branchID) {\n      name\n      website\n      timezone\n      streetAddress\n      addressCountry\n      addressLocality\n      addressRegion\n      postalCode\n      companyId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ChangeUserStateOfOnlineOrder($onlineOrderID: ID!, $state: OnlineOrderUserState!) {\n        storefront_changeUserState(onlineOrderID: $onlineOrderID, state: $state)\n    }\n"): (typeof documents)["\n    mutation ChangeUserStateOfOnlineOrder($onlineOrderID: ID!, $state: OnlineOrderUserState!) {\n        storefront_changeUserState(onlineOrderID: $onlineOrderID, state: $state)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OnlineOrderDetailPage($id: ID!) {\n    storefront_onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    storefront_onlineOrderBranchConfigurationByOrderID(id: $id) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_getOnlineOrderAvailability(onlineOrderId: $id) {\n      ...OnlineOrderAvailabilityItem\n    }\n  }\n"): (typeof documents)["\n  query OnlineOrderDetailPage($id: ID!) {\n    storefront_onlineOrder(id: $id) {\n      ...OnlineOrderItem\n    }\n\n    storefront_onlineOrderBranchConfigurationByOrderID(id: $id) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_getOnlineOrderAvailability(onlineOrderId: $id) {\n      ...OnlineOrderAvailabilityItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ModifyOnlineOrderCart($onlineOrderProductID: ID!, $amount: Int!) {\n        storefront_changeProductAmount(onlineOrderProductID: $onlineOrderProductID, amount: $amount)\n    }\n"): (typeof documents)["\n    mutation ModifyOnlineOrderCart($onlineOrderProductID: ID!, $amount: Int!) {\n        storefront_changeProductAmount(onlineOrderProductID: $onlineOrderProductID, amount: $amount)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    subscription OnUpdateOnlineOrder($id: ID!) {\n        storefront_onUpdateOnlineOrder(id: $id) {\n            onlineOrderID\n            timestamp\n        }\n    }\n"): (typeof documents)["\n    subscription OnUpdateOnlineOrder($id: ID!) {\n        storefront_onUpdateOnlineOrder(id: $id) {\n            onlineOrderID\n            timestamp\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation OnlineOrderFillPersonalData($onlineOrderID: ID!, $input: UserContactInfoInput!) {\n        storefront_userFillsContactInfo(onlineOrderID: $onlineOrderID, input: $input)\n    }\n"): (typeof documents)["\n    mutation OnlineOrderFillPersonalData($onlineOrderID: ID!, $input: UserContactInfoInput!) {\n        storefront_userFillsContactInfo(onlineOrderID: $onlineOrderID, input: $input)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_isOnlineOrderOpen($branchID: ID!) {\n    storefront_isOnlineOrderOpen(branchID: $branchID) {\n      isDeliveryAvailable\n      isPickupAvailable\n    }\n  }\n"): (typeof documents)["\n  query storefront_isOnlineOrderOpen($branchID: ID!) {\n    storefront_isOnlineOrderOpen(branchID: $branchID) {\n      isDeliveryAvailable\n      isPickupAvailable\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ServiceItem on ServiceMenu {\n    id\n    title\n    menuProducts {\n      ...ServiceProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n"): (typeof documents)["\n  fragment ServiceItem on ServiceMenu {\n    id\n    title\n    menuProducts {\n      ...ServiceProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment ServiceProductItem on NailAppointmentMenuProduct {\n        id\n        title\n        code\n        description\n        images\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            ...MenuProductConfigurationItem\n        }\n\n        appointmentMenuProductData {\n            menuProductId\n            duration\n            nailAppointmentMenuProductTeams {\n                id\n                team {\n                    id\n                    teamMember {\n                        id\n                        user {\n                            firstName\n                            lastName\n                        }\n                    }\n                }\n            }\n        }\n        menuId\n        sectionId\n    }\n"): (typeof documents)["\n    fragment ServiceProductItem on NailAppointmentMenuProduct {\n        id\n        title\n        code\n        description\n        images\n        enabled\n        section {\n            ...MenuSectionItem\n        }\n        configurations {\n            ...MenuProductConfigurationItem\n        }\n\n        appointmentMenuProductData {\n            menuProductId\n            duration\n            nailAppointmentMenuProductTeams {\n                id\n                team {\n                    id\n                    teamMember {\n                        id\n                        user {\n                            firstName\n                            lastName\n                        }\n                    }\n                }\n            }\n        }\n        menuId\n        sectionId\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment BranchItem on StoreFront_Branch {\n    id\n    name\n    website\n\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    contactPhone\n    contactEmail\n\n    openingTimes {\n      service\n      closeTime\n      openTime\n      dayOfWeek\n    }\n\n    company {\n      id\n      logo\n      name\n      hubPage {\n        banner\n      }\n      settings {\n        currency\n        customerDefaultLanguage\n        timezone\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment BranchItem on StoreFront_Branch {\n    id\n    name\n    website\n\n    streetAddress\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    contactPhone\n    contactEmail\n\n    openingTimes {\n      service\n      closeTime\n      openTime\n      dayOfWeek\n    }\n\n    company {\n      id\n      logo\n      name\n      hubPage {\n        banner\n      }\n      settings {\n        currency\n        customerDefaultLanguage\n        timezone\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuItem on Storefront_Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuItem on Storefront_Menu {\n    id\n    title\n    menuProducts {\n      ...MenuProductItem\n    }\n    sections {\n      ...MenuSectionItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductItem on Storefront_MenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    allergics {\n      code\n      title\n    }\n    menuId\n    sectionId\n\n    labels\n  }\n"): (typeof documents)["\n  fragment MenuProductItem on Storefront_MenuProduct {\n    id\n    title\n    code\n    description\n    images\n    enabled\n    ingredients\n    section {\n      ...MenuSectionItem\n    }\n    configurations {\n      ...MenuProductConfigurationItem\n    }\n\n    allergics {\n      code\n      title\n    }\n    menuId\n    sectionId\n\n    labels\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationItem on MenuProductConfiguration {\n    id\n    title\n    type\n    values {\n      ...MenuProductConfigurationValueItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"): (typeof documents)["\n  fragment MenuProductConfigurationValueItem on MenuProductConfigurationValue {\n    id\n    name\n    price\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n    enabled\n    timeRule{\n      __typename\n      ... on MenuSectionAlwaysTimeRule {\n        type\n      }\n    \n      __typename\n      ... on MenuSectionRangeTimeRule {\n        type\n        start\n        end\n        daysOfWeek\n        isAllDays\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment MenuSectionItem on MenuSection {\n    id\n    name\n    description\n    code\n    enabled\n    timeRule{\n      __typename\n      ... on MenuSectionAlwaysTimeRule {\n        type\n      }\n    \n      __typename\n      ... on MenuSectionRangeTimeRule {\n        type\n        start\n        end\n        daysOfWeek\n        isAllDays\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderAvailabilityItem on OnlineOrderAvailability {\n    pickup {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n    delivery {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderAvailabilityItem on OnlineOrderAvailability {\n    pickup {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n    delivery {\n      now\n      orderForLater {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderBranchConfigurationItem on Storefront_OnlineOrderBranchConfiguration {\n    branchID\n    menu {\n      ...MenuItem\n    }\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n    defaultPreparingTime\n    categoryStyle\n    pickupDiscountAmount\n    pickupDiscountType\n    deliveryDiscountAmount\n    deliveryDiscountType\n    autoDeliveryConfirm\n    autoPickupConfirm\n    pickupEnable\n    deliveryEnable\n    minimumOrderAmountForDelivery\n  }\n"): (typeof documents)["\n  fragment OnlineOrderBranchConfigurationItem on Storefront_OnlineOrderBranchConfiguration {\n    branchID\n    menu {\n      ...MenuItem\n    }\n    deliveryTableData {\n      id\n      deliveryDistance\n      deliveryTime\n      deliveryFee\n    }\n    defaultPreparingTime\n    categoryStyle\n    pickupDiscountAmount\n    pickupDiscountType\n    deliveryDiscountAmount\n    deliveryDiscountType\n    autoDeliveryConfirm\n    autoPickupConfirm\n    pickupEnable\n    deliveryEnable\n    minimumOrderAmountForDelivery\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n"): (typeof documents)["\n  fragment OnlineOrderStateEventItem on OnlineOrderStateEvent {\n    id\n    state\n    timestamp\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n    discountable\n    menuProduct {\n      id\n      images\n      code\n      section {\n        name\n        id\n        code\n        description\n        timeRule {\n          __typename\n          ... on MenuSectionAlwaysTimeRule {\n            type\n          }\n          ... on MenuSectionRangeTimeRule {\n            type\n            start\n            end\n            isAllDays\n            daysOfWeek\n          }\n        }\n      }\n      discountable\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderProductsItem on OnlineOrderProduct {\n    id\n    title\n    quantity\n    discountable\n    menuProduct {\n      id\n      images\n      code\n      section {\n        name\n        id\n        code\n        description\n        timeRule {\n          __typename\n          ... on MenuSectionAlwaysTimeRule {\n            type\n          }\n          ... on MenuSectionRangeTimeRule {\n            type\n            start\n            end\n            isAllDays\n            daysOfWeek\n          }\n        }\n      }\n      discountable\n    }\n\n    configurations {\n      id\n      title\n      value\n      price\n\n      menuProductConfigurationId\n      menuProductConfigurationValueId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment OnlineOrderItem on Storefront_OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    expectedTime\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    deliveryDiscount\n    pickupDiscount\n    totalPrice\n\n    createdAt\n    updatedAt\n    userCreatedAt\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    branch {\n      ...BranchItem\n    }\n  }\n"): (typeof documents)["\n  fragment OnlineOrderItem on Storefront_OnlineOrder {\n    id\n\n    userState\n    confirmationState\n    deliveryMode\n    state\n\n    estimatedDuration\n    deliveryFeeCalculationStatus\n    streetAddress\n    streetAddress2\n    addressLocality\n    addressRegion\n    postalCode\n    addressCountry\n\n    expectedTime\n\n    guestName\n    email\n    phone\n    message\n\n    basePrice\n    deliveryPrice\n    deliveryDiscount\n    pickupDiscount\n    totalPrice\n\n    createdAt\n    updatedAt\n    userCreatedAt\n\n    events {\n      ...OnlineOrderStateEventItem\n    }\n\n    onlineOrderProducts {\n      ...OnlineOrderProductsItem\n    }\n\n    branch {\n      ...BranchItem\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment OpeningTimeItem on OpeningTime {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n        service\n    }\n"): (typeof documents)["\n    fragment OpeningTimeItem on OpeningTime {\n        id\n        branchId\n        dayOfWeek\n        openTime\n        closeTime\n        note\n        isActive\n        service\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ReservationAvailabilityItem on ReservationAvailability {\n    slots {\n      startTime\n      spotsOpen\n      spotsTotal\n    }\n  }\n"): (typeof documents)["\n  fragment ReservationAvailabilityItem on ReservationAvailability {\n    slots {\n      startTime\n      spotsOpen\n      spotsTotal\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n   mutation CreateOnlineOrder($branchID: ID!, $input: OnlineOrderCreateInput!) {\n        storefront_createOnlineOrder(branchID: $branchID, input: $input) {\n            id\n        }\n   }\n"): (typeof documents)["\n   mutation CreateOnlineOrder($branchID: ID!, $input: OnlineOrderCreateInput!) {\n        storefront_createOnlineOrder(branchID: $branchID, input: $input) {\n            id\n        }\n   }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation OnlineOrderSetPickedProducts($onlineOrderID: ID!, $products: [OnlineOrderPickedProductInput!]!, $state: OnlineOrderUserState, $customerLanguage: String!) {\n    storefront_onlineOrderSetPickedProducts(onlineOrderID: $onlineOrderID, products: $products, userState: $state, customerLanguage: $customerLanguage)\n  }\n"): (typeof documents)["\n  mutation OnlineOrderSetPickedProducts($onlineOrderID: ID!, $products: [OnlineOrderPickedProductInput!]!, $state: OnlineOrderUserState, $customerLanguage: String!) {\n    storefront_onlineOrderSetPickedProducts(onlineOrderID: $onlineOrderID, products: $products, userState: $state, customerLanguage: $customerLanguage)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProductPickerPage($branchID: ID!) {\n    storefront_onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_branch(id: $branchID) {\n      ...BranchItem\n    }\n\n    storefront_isHolidayNow(branchId: $branchID)\n\n    storefront_isOnlineOrderAvailable(branchId: $branchID)\n  }\n"): (typeof documents)["\n  query ProductPickerPage($branchID: ID!) {\n    storefront_onlineOrderBranchConfiguration(branchID: $branchID) {\n      ...OnlineOrderBranchConfigurationItem\n    }\n\n    storefront_branch(id: $branchID) {\n      ...BranchItem\n    }\n\n    storefront_isHolidayNow(branchId: $branchID)\n\n    storefront_isOnlineOrderAvailable(branchId: $branchID)\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation Storefront_cancelReservation($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelReservation(id: $id, reason: $reason) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n"): (typeof documents)["\n  mutation Storefront_cancelReservation($id: ID!, $reason: CreateCancelReasonInput) {\n    storefront_cancelReservation(id: $id, reason: $reason) {\n      id\n      confirmationStatus\n      status\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateTableBooking($branchId: ID!, $input: Storefront_TableReservationCreate!) {\n    storefront_createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"): (typeof documents)["\n  mutation CreateTableBooking($branchId: ID!, $input: Storefront_TableReservationCreate!) {\n    storefront_createTableReservation(branchId: $branchId, input: $input) {\n      id\n      platformType\n      tableId\n      guestName\n      status\n      message\n      startTime\n      seats\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OpeningTimes($branchId: ID!) {\n    storefront_openingTimes(branchID: $branchId, filter: { service: TABLE_RESERVATION }) {\n      dayOfWeek\n      openTime\n      closeTime\n    }\n\n    storefront_tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      defaultBookingTimeDuration\n      hourBlocking\n      reservationLeadMinutes\n      reservationTraitMinutes\n      branch {\n        company {\n          settings {\n            timezone\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query OpeningTimes($branchId: ID!) {\n    storefront_openingTimes(branchID: $branchId, filter: { service: TABLE_RESERVATION }) {\n      dayOfWeek\n      openTime\n      closeTime\n    }\n\n    storefront_tableReservationConfiguration(branchID: $branchId) {\n      id\n      status\n      pause\n      defaultBookingTimeDuration\n      hourBlocking\n      reservationLeadMinutes\n      reservationTraitMinutes\n      branch {\n        company {\n          settings {\n            timezone\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ActiveTableReservationBranch($companyID: ID!) {\n    storefront_tableReservationBranches(companyId: $companyID) {\n      id\n      name\n      website\n      streetAddress\n      addressLocality\n      postalCode\n      tableReservationSetting {\n        seatLimit\n        phone\n        status\n        flexDuration\n        flexEnabled\n      }\n      company {\n        logo\n        settings {\n          customerDefaultLanguage\n          timezone\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ActiveTableReservationBranch($companyID: ID!) {\n    storefront_tableReservationBranches(companyId: $companyID) {\n      id\n      name\n      website\n      streetAddress\n      addressLocality\n      postalCode\n      tableReservationSetting {\n        seatLimit\n        phone\n        status\n        flexDuration\n        flexEnabled\n      }\n      company {\n        logo\n        settings {\n          customerDefaultLanguage\n          timezone\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query storefront_getTableReservationAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getTableReservationAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"): (typeof documents)["\n  query storefront_getTableReservationAvailability($branchId: ID!, $at: DateTime!) {\n    storefront_getTableReservationAvailability(branchId: $branchId, at: $at) {\n      slots {\n        startTime\n        spotsOpen\n        spotsTotal\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    subscription Storefront_tableReservationStatusUpdateSubscribe($id: ID!) {\n        storefront_tableReservationStatusUpdateSubscribe(id: $id) {\n            event\n            tableReservation {\n                id\n                confirmationStatus\n            }\n        }\n    }\n"): (typeof documents)["\n    subscription Storefront_tableReservationStatusUpdateSubscribe($id: ID!) {\n        storefront_tableReservationStatusUpdateSubscribe(id: $id) {\n            event\n            tableReservation {\n                id\n                confirmationStatus\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query Storefront_tableReservationStatus($id: ID!) {\n        storefront_tableReservationStatus(id: $id) {\n            ...Storefront_TableReservationItem\n        }\n    }\n"): (typeof documents)["\n    query Storefront_tableReservationStatus($id: ID!) {\n        storefront_tableReservationStatus(id: $id) {\n            ...Storefront_TableReservationItem\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;