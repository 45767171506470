import { Dialog, DialogBackdrop, DialogPanel } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { OnlineOrderState } from '@app/graphql/types/graphql.ts';
import Card from '@app/components/card/card.tsx';
import PrimaryButton from '@app/components/primary-button.tsx';
import { ClockIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useTranslate } from '@tolgee/react';
import { CheckCircleIcon } from '@heroicons/react/16/solid';
import CardContent from '@app/components/card/card-content.tsx';
import { DONE_STAGE_FIRST_ACCESS } from '@app/page/online-order/detail/online-order-detail-page.tsx';
import { StatusNotificationType } from '@app/page/online-order/order-detail-page/component/status-notification-type.ts';

export default function StatusNotification(props: {
  state: OnlineOrderState;
  mode: StatusNotificationType;
  phone: string;
  estimatedTime: string | null;
}) {
  const { t } = useTranslate();
  const [isFirstAccess, setIsFirstAccess] = useState(false);

  useEffect(() => {
    const storedTime = localStorage.getItem(DONE_STAGE_FIRST_ACCESS);

    if (!storedTime) {
      return;
    }

    const elapsedTime = Date.now() - parseInt(storedTime, 10);

    if (elapsedTime < 15000) {
      setIsFirstAccess(true);
    }
  }, [setIsFirstAccess]);

  const content = (
    <div
      onClick={() => {
        window.location.reload();
      }}
      className="flex flex-col items-center"
    >
      {/*Header*/}
      <div className="flex flex-col items-center gap-4">
        {props.state == OnlineOrderState.WaitingForConfirmation && (
          <>
            <ClockIcon className="h-16 w-16 text-yellow-500" />
            <p className="text-center text-3xl font-medium text-yellow-500">
              {t('online-order.status-noti.pending.header')}
            </p>
          </>
        )}

        {props.state == OnlineOrderState.InProgress && (
          <>
            <ClockIcon className="h-16 w-16 text-yellow-500" />
            <p className="max-w-2xl text-center text-3xl font-medium text-yellow-500">
              {t('online-order.status-noti.in-progress.header-1', 'Your order has been confirmed')}
            </p>
            <p className="-mt-2.5 max-w-2xl text-center text-3xl font-medium text-yellow-500">
              {t('online-order.status-noti.in-progress.header-2', 'The restaurant is now preparing your food.')}
            </p>
          </>
        )}

        {props.state == OnlineOrderState.Done && (
          <>
            <CheckCircleIcon className="h-16 w-16 text-green-500" />
            <p className="text-center text-3xl font-medium text-green-500">
              {t('online-order.status-noti.confirm.header', 'Your order has been completed.')}
            </p>
          </>
        )}

        {props.state == OnlineOrderState.Canceled && (
          <>
            <XCircleIcon className="h-16 w-16 text-center text-red-500" />
            <p className="text-3xl font-medium text-red-500">
              {t('online-order.status-noti.cancelled.header', 'Your order has been cancelled')}
            </p>
          </>
        )}
      </div>

      {props.estimatedTime && (
        <p className="mt-6 text-3xl">
          {t('estimate-time', 'Estimate completion time {time}', {
            time: props.estimatedTime,
          })}
        </p>
      )}

      {/*Content*/}
      <div className="mt-4 text-lg text-gray-500">
        {props.phone && (
          <p>
            1.{' '}
            {t(
              'online-order.status-noti.phone',
              'If you have any questions, please contact the restaurant by phone at'
            )}{' '}
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="underline"
              href={`tel:${props.phone}`}
            >
              {props.phone}
            </a>
          </p>
        )}
        <p>
          2. <span>{t('online-order.status-noti.update-on-1', 'You will receive confirmation and updates')}</span>{' '}
          <span className="underline">{t('online-order.status-noti.update-on-2', 'on this page or via email. ')}</span>
        </p>
        <p>
          3. <span>{t('online-order.status-noti.check-mail-1', 'Please check email')}</span>{' '}
          <span className="underline">{t('online-order.status-noti.check-mail-2', 'in your spam folder')}</span>{' '}
          <span>
            {t(
              'online-order.status-noti.check-mail-3',
              'and mark our email address as a priority to avoid missing any notifications.'
            )}
          </span>
        </p>
        <p>4. {t('online-order.status-noti.refresh', 'Press F5 or click here to update latest status.')}</p>
      </div>
    </div>
  );

  if (props.mode === StatusNotificationType.CARD) {
    return (
      <Card>
        <CardContent>{content}</CardContent>
      </Card>
    );
  }

  return (
    <Dialog
      open={isFirstAccess}
      className="relative z-10 focus:outline-none"
      onClose={() => {
        setIsFirstAccess(false);
      }}
    >
      <DialogBackdrop transition={true} className="fixed inset-0 bg-black/30" />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="max-w-[1080px] rounded-xl border bg-white p-6 backdrop-blur-2xl duration-300 ease-out data-[closed]:opacity-0"
          >
            {content}
            <div className="mt-6 flex w-full justify-center">
              <PrimaryButton
                className="h-10 w-48 p-2 text-lg"
                onClick={() => {
                  setIsFirstAccess(false);
                }}
              >
                {t('common.button.continue', 'Continue')}
              </PrimaryButton>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
